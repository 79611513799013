@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

/** CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #039be5;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-shade: #006db3;
    --ion-color-primary-tint: #63ccff;

    /** secondary **/
    --ion-color-secondary: #e54e03;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-shade: #ab1400;
    --ion-color-secondary-tint: #ff803b;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #0d2840;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-font-family: "Lato", sans-serif !important;
    --ion-default-font: "Lato", sans-serif !important;

    /* Whites & Grays */
    --light-gray: #f5f5f5;
    --white: #ffffff;

    /* Blues */
    --primary-blue: #039be5;
    --light-blue: #63ccff;
    --dark-blue: #006db3;
    --gray-dark-blue: #3a506b;
    --gray-ultra-dark-blue: #0d2840;
    --gray-light-blue: #677c99;

    /* Compliment Oranges */
    --primary-orange: #e54e03;
    --light-orange: #ff803b;
    --dark-orange: #ab1400;

    /* Font Sizes */
    --x-small-font-size: 0.8em;
    --small-font-size: 0.9em;
    --regular-font-size: 1em;
    --large-font-size: 1.5em;
    --x-large-font-size: 2em;

    /* Font Weights */
    --light-font-weight: 300;
    --regular-font-weight: 400;
    --bold-font-weight: 700;

    /* Border Radius */
    --budget-card-border-radius: 2px;
    --form-container-border-radius: 10px;
    --button-border-radius: 5px;
    --input-border-radius: 5px;

    /* Border Strokes */
    --default-border-stroke: 1px;

    /* Gradients */
    --main-gradient: linear-gradient(
        222.96deg,
        rgba(72, 194, 197, 1) 21.69%,
        rgba(48, 170, 196, 1) 34.71%,
        rgba(22, 144, 194, 1) 52.16%,
        rgba(6, 128, 193, 1) 67.91%,
        rgba(1, 123, 193, 1) 80.34%,
        rgba(0, 122, 193, 1) 100%
    );

    /* Box Shadows */
    --form-container-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}