@import "./styles/shared.scss";

.App {
  text-align: center;
  background: var(--main-gradient);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-logo {
  height: 7vw;
  pointer-events: none;
}

.App-header {
  flex: 1;
  margin: 7vw;
}

.App-body {
  flex: 4;
  width: 92vw;
  max-width: 70rem;
  min-height: 10vw;

  .Body-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Left-container {
      // background: var(--light-gray);
      // border-radius: var(--form-container-border-radius);

      height: 100%;
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      color: var(--light-gray);

      .Header-title {
        text-align: center;
        font-size: 2em;
        margin: 0;
      }

      .Body-text {
        padding: 0.5rem;
        line-height: 1.5rem;
        font-size: 1.1em;
      }
    }

    .Right-container {
      flex: 1;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: transparent;
      margin-left: 1rem;

      .App-image {
        height: 60vw;
        max-height: 40rem;
      }
    }
  }
}

.Privacy-container, .TermsOfService-container {
  text-align: left;
  font-size: 1em;
  line-height: 1.6rem;
  padding: 3vw;
}

.Footer-container {
  flex: 1;
  text-align: center;
  justify-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  flex-wrap: wrap;

  .Cutback-name {
    width: 100%;
  }

  .Footer-text {
    margin: 0.5rem;
    a {
      color: var(--light-gray);
    }
  }
}

@media only screen and (min-width: 414px) {
  .Privacy-container, .TermsOfService-container {
    font-size: 1.1rem;
    line-height: 1.6rem;
    padding: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .App-header {
    flex: 1;
    margin: 3rem;
  }

  .App-body {
    .Body-container {
      flex-direction: row;

      .Left-container {
        .Header-title {
          font-size: 3em;
        }

        .Body-text {
          line-height: 1.9rem;
          font-size: 1.3rem;
        }
      }

      .Right-container {
        .App-image {
          height: 70%;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .App-logo {
    height: 3rem;
    pointer-events: none;
  }

  .App-body {
    .Body-container {
      flex-direction: row;

      .Right-container {
        .App-image {
          height: 100%;
        }
      }
    }
  }
}
